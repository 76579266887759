/* eslint-disable no-undef */
<template>
  <div>
    <div class="header">
      <h4>招聘功能入口</h4>
    </div>
    <el-row :gutter="12" style="margin-top: 20px">
      <el-col
        :span="8"
        style="margin-top: 12px"
        v-access
        data-ctrl="recruit.enterCard"
      >
        <el-card shadow="always" v-prev path="/system/recruit/list">
          <el-button size="medium" type="text">招聘职位列表</el-button>
        </el-card>
      </el-col>
      <el-col
        :span="8"
        style="margin-top: 12px"
        v-access
        data-ctrl="recruit.returnCard"
      >
        <el-card shadow="always" v-prev path="/system/recruit/payList">
          <el-button size="medium" type="text">查看所有结算清单</el-button>
        </el-card>
      </el-col>
      <el-col
        :span="8"
        style="margin-top: 12px"
        v-access
        data-ctrl="recruit.viewCard"
      >
        <el-card shadow="always" v-prev path="/system/recruit/recruitView">
          <el-button size="medium" type="text"> 查看所有浏览记录</el-button>
        </el-card>
      </el-col>
      <el-col
        :span="8"
        style="margin-top: 12px"
        v-access
        data-ctrl="recruit.enlistCard"
      >
        <el-card shadow="always" v-prev path="/system/recruit/recruitEnlist">
          <el-button size="medium" type="text"> 查看所有报名人员</el-button>
        </el-card>
      </el-col>
      <el-col
        :span="8"
        style="margin-top: 12px"
        v-access
        data-ctrl="recruit.returnPlanCard"
      >
        <el-card shadow="always" v-prev path="/system/recruit/returnList">
          <el-button size="medium" type="text"> 管理返佣计划</el-button>
        </el-card>
      </el-col>
    </el-row>
    <div class="header">
      <h4>招聘统计</h4>
    </div>
    <el-row :gutter="12" style="margin-top: 32px">
      <el-col :span="8">
        <el-card shadow="always">
          <div slot="header" class="clearfix">
            <span>卡片名称</span>
            <el-button style="float: right; padding: 3px 0" type="text"
              >刷新</el-button
            >
          </div>

          <RecruitEchart1></RecruitEchart1>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="always">
          <div slot="header" class="clearfix">
            <span>卡片名称</span>
            <el-button style="float: right; padding: 3px 0" type="text"
              >刷新</el-button
            >
          </div>
          <RecruitEchart1></RecruitEchart1>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="always">
          <div slot="header" class="clearfix">
            <span>卡片名称</span>
            <el-button style="float: right; padding: 3px 0" type="text"
              >刷新</el-button
            >
          </div>
          <RecruitEchart1></RecruitEchart1>
        </el-card>
      </el-col>
    </el-row>

    <div class="header">
      <h4>帮助文档</h4>
    </div>
    <el-row :gutter="12" style="margin-top: 32px">
      <el-col :span="8">
        <el-card shadow="always">
          <el-button size="medium" type="text">职位发布流程</el-button>
        </el-card>
      </el-col>
      <el-col :span="8">
        <a
          href="/content/kpce6tnz0/document.html?skin=document"
          target="_blank"
        >
          <el-card shadow="always">
            <el-button size="medium" type="text">返佣计划使用文档</el-button>
          </el-card>
        </a>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  components: {
    RecruitEchart1: () => import('./recruit-echart-1')
  },
  mounted() {}
};
</script>

<style></style>
